import { Trans } from "@lingui/macro";
import { useSelector, useDispatch } from 'react-redux';
import { IApplicationStore } from '../../../../store/index';
import { useEffect, useRef, useState } from 'react';
import { EDataActions, IOccupancyFilter, setDataStore } from '../../../../reducers/data';
import moment from 'moment';
import getCounting from '../../../../reducers/api/getCounting';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import Loader from '../Loader';
import config from '../../../../config';
import FadeAnimation from '../FadeAnimation';
import { EStatisticsDateType, EStatisticsType, EStatisticsInterval, EStatisticsGraphView, IAttendanceFilter } from '../../../../reducers/data';
import FilterDayWeekMonth from '../filters/FilterDayWeekMonth';


interface IProps {
}

const DashboardCount = (props: IProps) => {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)

    const [show, setShow] = useState(false)

    const marts = useSelector((store: IApplicationStore) => store.dataStore.marts)
    const selectedMart = useSelector((store: IApplicationStore) => store.dataStore.selectedDashboardCounter)
    const loadingM = useSelector((store: IApplicationStore) => store.loadingStore.loadingMarts)
    const loadingC = useSelector((store: IApplicationStore) => store.loadingStore.loadingCouting)
    const counting = useSelector((store: IApplicationStore) => store.dataStore.counting)
    const dashboardDate = useSelector((store: IApplicationStore) => store.dataStore.dashboardDate)

    useOnClickOutside(ref, () => {
        setShow(false)
    })

    useEffect(() => {
        if(typeof selectedMart === 'undefined' && marts.length) {
            dispatch(setDataStore(EDataActions.SET_DASHBOARD_COUNTER, marts[0]))

            let currentMoment = moment(dashboardDate)
            let myDate = currentMoment.format('YYYY-MM-DD')
            let lastYear = currentMoment.subtract(364, "days").format('YYYY-MM-DD')
            let year2 = currentMoment.subtract(364, "days").format('YYYY-MM-DD')
            let year3 = currentMoment.subtract(364, "days").format('YYYY-MM-DD')

            //val.name === "Návštevnosť" || val.name === "Mall" || val.name === "Navstevnost" || 

            console.log(marts)
            console.log( marts[0].zone.filter( val => (val.isAttendance === true) ).map( val => val.id ) )

            let filterZone : IAttendanceFilter = {
                localities: [],
                zones: marts[0].zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                dateType: EStatisticsDateType.day,
                dateRanges: [{
                        from: myDate,
                        to: myDate
                },
                {
                    from: lastYear,
                    to: lastYear
                },
                {
                    from: year2,
                    to: year2
                },
                {
                    from: year3,
                    to: year3
                }],
                type: EStatisticsType.column,
                graphView: EStatisticsGraphView.multi
            }

            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD, filterZone))

            let filterTableZone : IAttendanceFilter = {
                localities: [],
                zones:  marts[0].zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                dateType: EStatisticsDateType.day,
                dateRanges: [{
                    from: myDate,
                    to: myDate
            },
            {
                from: lastYear,
                to: lastYear
            },
            {
                from: year2,
                to: year2
            },
            {
                from: year3,
                to: year3
            }],
                type: EStatisticsType.table,
                interval: EStatisticsInterval.day,
                graphView: EStatisticsGraphView.multi
            }

            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_ZONE, filterTableZone))

            let filterPie : IAttendanceFilter = {
                localities: marts[0].location.map( val => val.id ),
                zones: [],
                dateType: EStatisticsDateType.day,
                dateRanges: [{
                        from: myDate,
                        to: myDate
                }],
                type: EStatisticsType.cake,
                graphView: EStatisticsGraphView.multi
            }

            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_PIE, filterPie))

            let filterTableLocation : IAttendanceFilter = {
                localities: marts[0].location.map( val => val.id ),
                zones: [],
                dateType: EStatisticsDateType.day,
                dateRanges: [{
                        from: myDate,
                        to: myDate
                },
                {
                    from: lastYear,
                    to: lastYear
                }],
                type: EStatisticsType.table,
                interval: EStatisticsInterval.day,
                graphView: EStatisticsGraphView.multi
            }

            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_LOCATION, filterTableLocation))

            let filterOccupancy : IOccupancyFilter = {
                zones: marts[0].zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                dateRanges: [{
                    from: myDate,
                    to: myDate
            }],
                dateType: EStatisticsDateType.day,
                type: EStatisticsType.xy,
                graphView: EStatisticsGraphView.zone
            }

            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_OCCUPANCY, filterOccupancy))

        }
    }, [dispatch, marts, selectedMart, dashboardDate])

    useEffect(() => {
        const fetchCounting = () => {   
            if(selectedMart) {
                dispatch(getCounting(selectedMart.id))
            }
        }

        const interval = window.setInterval(() => {
            fetchCounting()
        }, config.dashboard.interval*1000)
        
        fetchCounting()

        return () => {
            window.clearInterval(interval)
        }
    }, [dispatch, selectedMart])

    const loading = loadingM || loadingC

    if(marts.length === 0) {
        return null
    }

    let count = counting?counting.value:'-'
    let day = counting?moment(counting.date).format('DD. MM. YYYY'):'-'
    let hour = counting?moment(counting.date).format('HH:mm:ss'):'-'

    return (
        <>
            <div className={`box`}>
                <div className="pd-30">
                    <div className="text_s16 text_w700 dashboard-loader">
                        <Trans>Aktuálny počet návštevníkov</Trans>:
                        <Loader value={loading}/>
                    </div>

                    <div className="title_h22 text_nowrap color_green mgt-10"><FadeAnimation animKey={count.toString()}>{count}</FadeAnimation></div>

                    <div className="grid mgt-5">
                        <div className="col_default mgt-5">
                            <span className="btn_action text_w500 text_nowrap">
                                <svg className="sico color2 wh16"><use xlinkHref="/assets/layout/icons.svg#s_cal" /></svg>
                                <span><FadeAnimation animKey={day}>{day}</FadeAnimation></span>
                            </span>
                        </div>

                        <div className="col_default mgt-5">
                            <span className="btn_action text_w500 text_nowrap">
                                <svg className="sico color2 wh16"><use xlinkHref="/assets/layout/icons.svg#s_clock" /></svg>
                                <span><FadeAnimation animKey={hour}>{hour}</FadeAnimation></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div className={`element_drop dashboard basic ${show?'active':''}`}  ref={ref}>
                    <button type="button" className="btn_center w100p element_drop_button" onClick={() => setShow(true)}>
                        {selectedMart?(
                            <>
                                <span className="btn_center_image">
                                    <img src={selectedMart.logo} alt={selectedMart.name} />
                                </span>
                                <span className="btn_center_desc">
                                    <strong>{selectedMart.name}</strong>
                                    <span>{selectedMart.address}</span>
                                </span>
                            </>
                        ):(<span className="btn_center_desc">-</span>)}

                        <svg className="sico drop"><use xlinkHref="/assets/layout/icons.svg#s_ar1_d" /></svg>
                    </button>  

                    <div className="element_drop_inner">
                        <div className="element_drop_content item_center_list pd-15">   
                            {marts.map((m,i) => (
                                <label className="item_center type2" key={i}>
                                    <input 
                                        type="radio" 
                                        value={m.id} 
                                        name={`checkMart${m.id}`} 
                                        checked={selectedMart?.id===m.id?true:false}
                                        onChange={(e) => {
                                            dispatch(setDataStore(EDataActions.SET_DASHBOARD_COUNTER, m))


                                            let currentMoment = moment(dashboardDate)
                                            let myDate = currentMoment.format('YYYY-MM-DD')
                                            let lastYear = currentMoment.subtract(364, "days").format('YYYY-MM-DD')
                                            let year2 = currentMoment.subtract(364, "days").format('YYYY-MM-DD')
                                            let year3 = currentMoment.subtract(364, "days").format('YYYY-MM-DD')

                                            let filterZone : IAttendanceFilter = {
                                                localities: [],
                                                zones: m.zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                                                dateType: EStatisticsDateType.day,
                                                dateRanges: [{
                                                        from: myDate,
                                                        to: myDate
                                                },
                                                {
                                                    from: lastYear,
                                                    to: lastYear
                                                },
                                                {
                                                    from: year2,
                                                    to: year2
                                                },
                                                {
                                                    from: year3,
                                                    to: year3
                                                }],
                                                type: EStatisticsType.column,
                                                graphView: EStatisticsGraphView.multi
                                            }

                                            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD, filterZone))

                                            let filterTableZone : IAttendanceFilter = {
                                                localities: [],
                                                zones:  m.zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                                                dateType: EStatisticsDateType.day,
                                                dateRanges: [{
                                                    from: myDate,
                                                    to: myDate
                                            },
                                            {
                                                from: lastYear,
                                                to: lastYear
                                            },
                                            {
                                                from: year2,
                                                to: year2
                                            },
                                            {
                                                from: year3,
                                                to: year3
                                            }],
                                                type: EStatisticsType.table,
                                                interval: EStatisticsInterval.day,
                                                graphView: EStatisticsGraphView.multi
                                            }

                                            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_ZONE, filterTableZone))


                                            let filterPie : IAttendanceFilter = {
                                                localities: m.location.map( val => val.id ),
                                                zones: [],
                                                dateType: EStatisticsDateType.day,
                                                dateRanges: [{
                                                        from: myDate,
                                                        to: myDate
                                                }],
                                                type: EStatisticsType.cake,
                                                graphView: EStatisticsGraphView.multi
                                            }

                                            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_PIE, filterPie))

                                            let filterTableLocation : IAttendanceFilter = {
                                                localities: m.location.map( val => val.id ),
                                                zones: [],
                                                dateType: EStatisticsDateType.day,
                                                dateRanges: [{
                                                        from: myDate,
                                                        to: myDate
                                                },
                                                {
                                                    from: lastYear,
                                                    to: lastYear
                                                }],
                                                type: EStatisticsType.table,
                                                interval: EStatisticsInterval.day,
                                                graphView: EStatisticsGraphView.multi
                                            }

                                            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_LOCATION, filterTableLocation))

                                            let filterOccupancy : IOccupancyFilter = {
                                                zones: m.zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                                                dateRanges: [{
                                                    from: myDate,
                                                    to: myDate
                                            }],
                                                dateType: EStatisticsDateType.day,
                                                type: EStatisticsType.xy,
                                                graphView: EStatisticsGraphView.zone
                                            }

                                            dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_OCCUPANCY, filterOccupancy))

                                            setShow(false)
                                        }}
                                    />
                                    <div className="item_center_inner">
                                        <div className="item_center_image">
                                            <img src={m.logo} alt={m.name} />
                                        </div>
                                        <div className="item_center_desc">
                                            <strong>{m.name}</strong>
                                            <span>{m.address}</span>
                                        </div>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className={`box`}>
                    <div className="col_fill">
                    <div className="grid small-space">
                    <div className="pd-30">
                            <span className="btn_action text_w500 text_nowrap">
                                <svg className="sico color2 wh16"><use xlinkHref="/assets/layout/icons.svg#s_cal" /></svg>
                                <span><FadeAnimation animKey={dashboardDate}>{moment(dashboardDate).format('DD. MM. YYYY')}</FadeAnimation></span>
                            </span>
                    </div>
                    <div className="pd-10">
                        <div className="col_default mgt-5">
                            <FilterDayWeekMonth
                                dateType={[EStatisticsDateType.day]}
                                selectedDateType={EStatisticsDateType.day}
                                selectedRanges={[{
                                    from: dashboardDate,
                                    to: dashboardDate
                                }]}
                                onSelectedRanges={(ranges) => {
                                    let value = ranges.at(ranges.length - 1)?.from 
                                    dispatch(setDataStore(EDataActions.SET_DASHBOARD_DATE, value))

                                    let currentMoment = moment(value)
                                    let myDate = currentMoment.format('YYYY-MM-DD')
                                    let lastYear = currentMoment.subtract(364, "days").format('YYYY-MM-DD')
                                    let year2 = currentMoment.subtract(364, "days").format('YYYY-MM-DD')
                                    let year3 = currentMoment.subtract(364, "days").format('YYYY-MM-DD')

                                    // let mart = (typeof selectedMart === 'undefined' && marts.length)? marts[0] : selectedMart
                                    let martVal = selectedMart? selectedMart : marts[0] 

                                    let filterZone : IAttendanceFilter = {
                                        localities: [],
                                        zones: martVal.zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                                        dateType: EStatisticsDateType.day,
                                        dateRanges: [{
                                                from: myDate,
                                                to: myDate
                                        },
                                        {
                                            from: lastYear,
                                            to: lastYear
                                        },
                                        {
                                            from: year2,
                                            to: year2
                                        },
                                        {
                                            from: year3,
                                            to: year3
                                        }],
                                        type: EStatisticsType.column,
                                        graphView: EStatisticsGraphView.multi
                                    }

                                    dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD, filterZone))

                                    let filterTableZone : IAttendanceFilter = {
                                        localities: [],
                                        zones:  martVal.zone.filter( val => (val.isAttendance === true)  ).map( val => val.id ),
                                        dateType: EStatisticsDateType.day,
                                        dateRanges: [{
                                            from: myDate,
                                            to: myDate
                                    },
                                    {
                                        from: lastYear,
                                        to: lastYear
                                    },
                                    {
                                        from: year2,
                                        to: year2
                                    },
                                    {
                                        from: year3,
                                        to: year3
                                    }],
                                        type: EStatisticsType.table,
                                        interval: EStatisticsInterval.day,
                                        graphView: EStatisticsGraphView.multi
                                    }

                                    dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_ZONE, filterTableZone))


                                    let filterPie : IAttendanceFilter = {
                                        localities: martVal.location.map( val => val.id ),
                                        zones: [],
                                        dateType: EStatisticsDateType.day,
                                        dateRanges: [{
                                                from: myDate,
                                                to: myDate
                                        }],
                                        type: EStatisticsType.cake,
                                        graphView: EStatisticsGraphView.multi
                                    }

                                    dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_PIE, filterPie))

                                    let filterTableLocation : IAttendanceFilter = {
                                        localities: martVal.location.map( val => val.id ),
                                        zones: [],
                                        dateType: EStatisticsDateType.day,
                                        dateRanges: [{
                                                from: myDate,
                                                to: myDate
                                        },
                                        {
                                            from: lastYear,
                                            to: lastYear
                                        }],
                                        type: EStatisticsType.table,
                                        interval: EStatisticsInterval.day,
                                        graphView: EStatisticsGraphView.multi
                                    }

                                    dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_LOCATION, filterTableLocation))

                                    let filterOccupancy : IOccupancyFilter = {
                                        zones: martVal.zone.filter( val => (val.isAttendance === true) ).map( val => val.id ),
                                        dateRanges: [{
                                            from: myDate,
                                            to: myDate
                                    }],
                                        dateType: EStatisticsDateType.day,
                                        type: EStatisticsType.xy,
                                        graphView: EStatisticsGraphView.zone
                                    }

                                    dispatch(setDataStore(EDataActions.SET_FILTER_DASHBOARD_OCCUPANCY, filterOccupancy))
                                }}
                            />
                        </div>  
                    </div>
                </div>
                </div>
            </div>
        </>
    )
};

export default DashboardCount

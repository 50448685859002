import moment from 'moment';
import { Reducer } from 'redux'
import { IMart, IReport, IEvent, IMapInfo } from '../js/interfaces/types';
import { EUserActions } from './user';

export interface IReportFilter {
    type: {
        id: string,
        title: string
    }[],
    dayFrom?: string,
    dayTo?: string
}

export enum EStatisticsDateType {
    month = 'month',
    day = 'day',
    week = 'week',
    year = 'year',
    custom = 'custom',
}

export enum EStatisticsType {
    column = 'column',
    cake = 'pie',
    xy = 'XY',
    table = 'table'
}

export enum EStatisticsInterval {
    min15 = '15minute',
    min30 = '30minute',
    min60 = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    custom = 'custom'
}

export enum EStatisticsGraphView {
    multi = 'none',
    zone = 'entry',
    date = 'date'
}

export enum EStatisticsGender {
    all = 'all',
    woman = 'woman',
    man = 'men'
}

export enum EStatisticsGenderType {
    percentage = 'percentage',
    number = 'number'
}
export interface IAttendanceFilter {
    localities: number[],
    zones: number[],
    dateType: EStatisticsDateType,
    dateRanges: {
        from: string,
        to: string
    }[],
    type: EStatisticsType,
    interval?: EStatisticsInterval,
    graphView?: EStatisticsGraphView
}

export interface IGenderFilter {
    localities: number[],
    zones: number[],
    dateType: EStatisticsDateType,
    dateRanges: {
        from: string,
        to: string
    }[],
    type: EStatisticsType,
    interval?: EStatisticsInterval,
    graphView?: EStatisticsGraphView,
    gender?: EStatisticsGender,
    genderType?: EStatisticsGenderType
}

export interface IDwellTimeFilter {
    zones: number[]
    dateType: EStatisticsDateType,
    dateRanges: {
        from: string,
        to: string
    }[]
}

export interface IOccupancyFilter {
    zones: number[],
    dateType: EStatisticsDateType,
    dateRanges: {
        from: string,
        to: string
    }[],
    type: EStatisticsType,
    graphView?: EStatisticsGraphView
}

export interface IHeatMapFilter {
    localities: number[],
    zones: number[],
    dateType: EStatisticsDateType,
    dateRanges: {
        from: string,
        to: string
    }[]
}

export interface IDataStore {
    showContactModal: boolean,
    marts: IMart[],
    martMaps: IMapInfo[],
    selectedMapMart?: IMart,
    selectedMarts: IMart[],
    selectedDashboardCounter?: IMart,
    reports: IReport[],
    reportsFilter: IReportFilter,
    activities: IEvent[],
    counting?: {
        date: string,
        value: number
    },
    dashboardDate: string, 
    filterAttendance: IAttendanceFilter,
    filterGender: IGenderFilter,
    filterDwell: IDwellTimeFilter,
    filterOccupancy: IOccupancyFilter,
    filterHeatmap: IHeatMapFilter,
    filterDashboard: IAttendanceFilter,
    filterDashboardPie: IAttendanceFilter,
    filterDashboardOccupancy: IOccupancyFilter,
    filterDashboardLocation: IAttendanceFilter,
    filterDashboardZone: IAttendanceFilter
}

export enum EDataActions {
    RESET_STATE = 'RESET_STATE',
    SHOW_CONTACT = 'SHOW_CONTACT',
    GET_MARTS = 'GET_MARTS',
    SET_SELECTED_MAP_MART = 'SET_SELECTED_MART',
    SET_SELECTED_MARTS = 'SET_SELECTED_MARTS',
    GET_REPORTS = 'GET_REPORTS',
    SET_REPORT_FILTERS = 'SET_REPORT_FILTERS',
    GET_ACTIVITIES = 'GET_ACTIVITIES',
    SET_DASHBOARD_COUNTER = 'SET_DASHBOARD_COUNTER',
    SET_COUNTING = 'SET_COUNTING',
    SET_DASHBOARD_DATE = 'SET_DASHBOARD_DATE',
    SET_FILTER_ATTENDANCE = 'SET_FILTER_ATTENDANCE',
    SET_FILTER_DWELL = 'SET_FILTER_DWELL',
    SET_FILTER_OCCUPANCY = 'SET_FILTER_OCCUPANCY',
    SET_FILTER_GENDER = 'SET_FILTER_GENDER',
    SET_FILTER_HEATMAP = 'SET_FILTER_HEATMAP',
    SET_FILTER_DASHBOARD = 'SET_FILTER_DASHBOARD',
    SET_FILTER_DASHBOARD_PIE = 'SET_FILTER_DASHBOARD_PIE',
    SET_FILTER_DASHBOARD_OCCUPANCY = 'SET_FILTER_DASHBOARD_OCCUPANCY',
    SET_FILTER_DASHBOARD_LOCATION = 'SET_FILTER_DASHBOARD_LOCATION',
    SET_FILTER_DASHBOARD_ZONE = 'SET_FILTER_DASHBOARD_ZONE',
    SET_MART_MAP = "SET_MART_MAP"
}

const initialState: IDataStore = {
    showContactModal: false,
    marts: [],
    martMaps: [],
    selectedMapMart: undefined,
    selectedMarts: [],
    reports: [],
    reportsFilter: {
        type: [],
        dayFrom: moment().format('YYYY-MM-DD'),
        dayTo: moment().subtract(14,'d').format('YYYY-MM-DD')
    },
    activities: [],
    selectedDashboardCounter: undefined,
    counting: undefined,
    dashboardDate: moment().subtract(1, 'd').format("YYYY-MM-DD"),
    filterAttendance: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.column,
        graphView: EStatisticsGraphView.multi
    },
    filterGender: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.column,
        graphView: EStatisticsGraphView.multi,
        gender: EStatisticsGender.all,
        genderType: EStatisticsGenderType.number
    },
    filterDwell: {
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: []
    },
    filterOccupancy: {
        zones: [],
        dateRanges: [],
        dateType: EStatisticsDateType.day,
        type: EStatisticsType.xy,
        graphView: EStatisticsGraphView.zone
    },
    filterHeatmap: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: []
    },
    filterDashboard: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.column,
        graphView: EStatisticsGraphView.multi
    },
    filterDashboardPie: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.cake,
        graphView: EStatisticsGraphView.multi
    },
    filterDashboardOccupancy: {
        zones: [],
        dateRanges: [],
        dateType: EStatisticsDateType.day,
        type: EStatisticsType.xy,
        graphView: EStatisticsGraphView.zone
    },
    filterDashboardLocation: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.column,
        graphView: EStatisticsGraphView.multi
    },
    filterDashboardZone: {
        localities: [],
        zones: [],
        dateType: EStatisticsDateType.day,
        dateRanges: [],
        type: EStatisticsType.column,
        graphView: EStatisticsGraphView.multi
    }
}

export function setDataStore(type: EDataActions, value?: any) {
    return { type: type, payload: value}
}

const reducer: Reducer<IDataStore> = (state = initialState, action) => {
    switch (action.type) {
        case EDataActions.SHOW_CONTACT:
            state = {
                ...state,
                showContactModal: action.payload
            }

            if(action.payload) {
                document.body.classList.add('show_popup')
            } else {
                document.body.classList.remove('show_popup')
            }
            break
        case EDataActions.RESET_STATE:
        case EUserActions.LOGOUT_USER:
            state = initialState
            break
        case EDataActions.GET_MARTS:
            state = {
                ...state,
                marts: [...action.payload],
                selectedMapMart: action.payload.length?action.payload[0]:undefined
            }

            break
        case EDataActions.SET_MART_MAP:
            state = {
                ...state,
                martMaps: action.payload
            }
            break;
        case EDataActions.SET_SELECTED_MAP_MART:
            state = {
                ...state,
                selectedMapMart: action.payload
            }
            break
        case EDataActions.SET_SELECTED_MARTS:
            state = {
                ...state,
                selectedMarts: action.payload
            }
            break
        case EDataActions.GET_REPORTS:
            state = {
                ...state,
                reports: action.payload
            }
            break
        case EDataActions.SET_REPORT_FILTERS:
            state = {
                ...state,
                reportsFilter: action.payload
            }
            break
        case EDataActions.GET_ACTIVITIES:
            state = {
                ...state,
                activities: action.payload
            }
            break
        case EDataActions.SET_DASHBOARD_COUNTER:
            state = {
                ...state,
                selectedDashboardCounter: action.payload
            }
            break
        case EDataActions.SET_COUNTING:
            state = {
                ...state,
                counting: action.payload
            }
            break
        case EDataActions.SET_DASHBOARD_DATE:
            state = {
                ...state,
                dashboardDate: action.payload
            }
            break
        case EDataActions.SET_FILTER_ATTENDANCE:
            state = {
                ...state,
                filterAttendance: action.payload
            }
            break
        case EDataActions.SET_FILTER_GENDER:
            state = {
                ...state,
                filterGender: action.payload
            }
            break
        case EDataActions.SET_FILTER_DWELL:
            state = {
                ...state,
                filterDwell: action.payload
            }
            break
        case EDataActions.SET_FILTER_OCCUPANCY:
            state = {
                ...state,
                filterOccupancy: action.payload
            }
            break
        case EDataActions.SET_FILTER_HEATMAP:
            state = {
                ...state,
                filterHeatmap: action.payload
            }
            break
        case EDataActions.SET_FILTER_DASHBOARD:
            state = {
                ...state,
                filterDashboard: action.payload
            }
            break
        case EDataActions.SET_FILTER_DASHBOARD_PIE:
            state = {
                ...state,
                filterDashboardPie: action.payload
            }
            break
        case EDataActions.SET_FILTER_DASHBOARD_OCCUPANCY:
            state = {
                ...state,
                filterDashboardOccupancy: action.payload
            }
            break
        case EDataActions.SET_FILTER_DASHBOARD_LOCATION:
            state = {
                ...state,
                filterDashboardLocation: action.payload
            }
            break
        case EDataActions.SET_FILTER_DASHBOARD_ZONE:
            state = {
                ...state,
                filterDashboardZone: action.payload
            }
            break
    }

    return state
}

export { reducer as dataReducer }
